import * as React from "react";
import { Container } from "react-bootstrap";
import { container, message } from "./CorpGroupBooking.module.scss";

export const StyledContainer = (props: any) => {
    return (<Container fluid={props.fluid ? props.fluid : null} className={`${container} ${props.className ? props.className : ""}`}>{props.children}</Container>);
};

export const MessageContainer = (props: any) => {
    return (<div className={`${message} text-center`}>{props.children}</div>);
};



